import React from 'react';
import RandomVideo from './RandomVideo';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <RandomVideo />
    </div>
  );
};

export default App;
